import React from "react";

export default function BitcoinIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-4/3"
    >
      <path
        d="M17.915 5.86633C20.5403 6.77066 22.4603 8.12627 22.0837 10.648C21.8106 12.4939 20.7867 13.3876 19.4281 13.7021C21.2936 14.6739 22.2424 16.1634 21.338 18.7455C20.217 21.9518 17.5502 22.2225 14.0045 21.5515L13.144 25L11.0647 24.4818L11.9139 21.0795C11.375 20.9456 10.8242 20.8035 10.2575 20.6495L9.40466 24.0678L7.32772 23.5496L8.18823 20.094C7.7032 19.9696 7.20987 19.8376 6.70648 19.7114L4 19.0369L5.03225 16.6561C5.03225 16.6561 6.56434 17.0636 6.54362 17.0334C7.13229 17.179 7.39346 16.7953 7.49651 16.5394L8.85981 11.0732L9.82988 7.18167C9.85594 6.73986 9.70315 6.18258 8.861 5.97234C8.89357 5.95043 7.35082 5.59687 7.35082 5.59687L7.90396 3.37601L10.7715 4.09202L10.7692 4.10268C11.2003 4.20987 11.6445 4.31173 12.0969 4.41478L12.9491 1L15.0273 1.5182L14.1922 4.86606C14.7501 4.99339 15.3115 5.1219 15.8576 5.25811L16.6867 1.93217L18.7666 2.45036L17.915 5.86633Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7366 17.6683L12.5609 14.3637C13.3312 14.5373 14.3348 14.7704 15.2083 15.1668C15.7771 15.4249 16.2119 15.7207 16.4681 16.0482C16.6926 16.3353 16.7963 16.6629 16.6979 17.1049L16.6869 17.149C16.5806 17.5757 16.342 17.8173 15.9933 17.9695C15.61 18.1366 15.0816 18.1961 14.4543 18.1635C13.4998 18.1138 12.4955 17.8667 11.7366 17.6683Z"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
      <path
        d="M17.3296 10.4497C17.2321 10.8401 17.0298 11.049 16.7615 11.1794C16.4611 11.3254 16.042 11.3868 15.5302 11.368C14.7888 11.3408 14.015 11.1575 13.3998 10.9969L14.1184 8.11532C14.7436 8.25536 15.5139 8.44022 16.1886 8.75867C16.6484 8.97569 16.9855 9.22235 17.1785 9.49169C17.3506 9.73175 17.4357 10.0245 17.3296 10.4497Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
